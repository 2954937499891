import { Dialog } from 'element-ui';
import EButton from '@/components/button/index.vue';
import Vue from 'vue';
Vue.use(Dialog);

export default {
  props: {
    confirm_text: {
      type: String,
      default: '确认',
    },
    // 默认需要的参数
    ...Dialog.$props,
    // ...PopupManager.$props,

    openDelay: {},
    closeDelay: {},
    zIndex: {},
    // modal: {
    //   type: Boolean,
    //   default: false
    // },
    modalFade: {
      type: Boolean,
      default: true,
    },
    modalClass: {},
    modalAppendToBody: {
      type: Boolean,
      default: false,
    },
    lockScroll: {
      type: Boolean,
      default: true,
    },
    closeOnPressEscape: {
      type: Boolean,
      default: false,
    },
    closeOnClickModal: {
      type: Boolean,
      default: false,
    },
    // 是否显示
    visible: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    // 是否自定义按钮
    custom: {
      type: Boolean,
      default: false,
    },
    // 标题
    title: {
      type: String,
      default: '',
    },
    // 自定义类名
    'custom-class': {
      type: String,
      default: '',
    },
    // 是否显示关闭键
    'show-close': {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      // default:`${476 / 1920 * 100}vw`
      default: '476px',
    },
    minWidth: {
      type: String,
      default: `${(476 / 1920) * 1024}px`,
    },
    top: {
      type: String,
      default: '15vh',
    },
    // 是否在body滚动
    'lock-scroll': {
      type: Boolean,
      default: false,
    },

    // 是否显示取消按钮
    showCancel: {
      type: Boolean,
      default: true,
    },
    // 是否显示删除按钮
    showDelete: {
      type: Boolean,
      default: true,
    },
    // 删除是否提示
    showDelInfo: {
      type: Object,
      default() {
        return {
          show: true,
          tipsText: '',
        };
      },
    },
    // 按钮样式
    btnsText: {
      type: Object,
      default() {
        return {
          confirm: '',
          cancel: '',
          delete: '',
          name: '',
          type: 'btn-1-12',
          size: 'medium',
        };
      },
    },
    // 是否展示底部操作div
    hideFooter: {
      type: Boolean,
      default: false,
    },

    // 除了取消按钮【点击取消都关闭】，点击其他按钮自动关闭弹窗
    // autoClose:{
    //   type: Boolean,
    //   default: true
    // },

    // 弹框是否插入body
    'append-to-body': {
      type: Boolean,
      default: false,
    },
    // 是否点击遮罩关闭
    'close-on-click-modal': {
      type: Boolean,
      default: false,
    },

    // 是否点击遮罩关闭
    show_close: {
      type: Boolean,
      default: true,
    },
    // 关闭时销毁 Dialog 中的元素
    'destroy-on-close': {
      type: Boolean,
      default: false,
    },
    cancelClose: {
      type: Boolean,
      default: true,
    },

    // 关闭前
    beforeClose: {
      type: Function,
      default: (done) => {
        done();
      },
    },
  },

  components: {
    EButton,
  },

  data() {
    return {
      dialogVisible: this.visible,

      tips: {
        show: false,
      },
    };
  },
  methods: {
    // 点击删除
    onclickDel() {
      if (!this.showDelInfo.show) {
        this.$emit('delete');

        // 自动关闭弹框
        // if (this.autoClose){
        //   this.$emit('update:visible', false);
        // }
      } else {
        this.tips.show = true;
      }
    },

    // 点击取消
    onclickCancel() {
      this.$emit('cancel');
      if (this.cancelClose) {
        this.$emit('update:visible', false);
      }
    },

    // 点击确认
    onclickConfirm() {
      this.$emit('confirm');

      // 自动关闭弹框
      // if (this.autoClose){
      //   this.$emit('update:visible', false);
      // }
    },

    // 提示框确认时
    tipsConfirm() {
      // 自动关闭弹框
      // if (this.autoClose){
      //   this.$emit('update:visible', false);
      // }
      this.tips.show = false;
      this.$emit('delete');
    },

    // 设置最小宽度
    setStyle() {
      // console.log(this.$refs.elDialog.$refs.dialog)
      this.$refs.elDialog.$refs.dialog.style.cssText += `min-width:${this.minWidth}`;
    },
  },
  mounted() {
    // this.setStyle();
  },
  computed: {
    // 返回删除提示文本
    reTipsText() {
      return this.$t('components.delTips');
    },
    // 返回确认按钮文本
    reConfirmText() {
      return this.$t('components.confirm');
    },
    // 返回删除按钮文本
    reDeleteText() {
      return this.$t('components.delete');
    },
    // 返回取消按钮文本
    reCancelText() {
      return this.$t('components.cancel');
    },
    // 返回我知道了按钮文本
    iknowText() {
      return this.$t('components.Iknow');
    },
  },
  watch: {
    // 监听选择框
    dialogVisible() {
      this.$emit('update:visible', this.dialogVisible);
    },
    // 监听外部传值
    visible() {
      this.dialogVisible = this.visible;
    },
  },
};
