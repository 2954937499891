/**
 * @desc 基础数据 教师
 */
import { Table, TableColumn, Input, Button, Checkbox } from 'element-ui';
import basicHeader from '@/components/backStageHeader';
import searchInput from '@/components/searchInput';
import qlPagination from '@/components/qlPagination';
import teacherDialog from './components/teacherDialog';
import passwordDialog from './components/passwordDialog';
import batchDeleteDialog from './components/batchDeleteDialog.vue';
import ETable from '@/components/table/index.vue';
import OperationBtns from '@components/table/components/operationBtns.vue';
import EButton from '@components/button/index.vue';

// api
import { getTeacherTable, deleteTeachers } from '@/api/basic';

// const
import * as consts from './constance';

export default {
  name: 'basic-teacher',

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    basicHeader,
    searchInput,
    qlPagination,
    teacherDialog,
    passwordDialog,
    batchDeleteDialog,
    ETable,
    EButton,
  },

  data() {
    return {
      table: {
        list: [],
        total: 0,
      },

      teacherDialogData: {
        visible: false,
        optionType: consts.DIALOG_TYPE_ADD,
        data: {},
      },

      passwordDialogData: {
        visible: false,
        optionType: consts.PASSWORD_RESET_ONE,
        teachers: [],
      },
      batchDeleteDialogData: {
        visible: false,
      },

      selectedRows: [],
      filterOptions: {
        commonFilter: '',
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,

        pageChange: this.pageChange,
      },
    };
  },

  methods: {
    handleSelectAll() {
      this.getAllTableData();
    },

    pageChange() {
      this.getTableData();
    },

    // handle 新增
    handleAdd() {
      // if (this.notAllowOperate) return;
      this.teacherDialogData.optionType = consts.DIALOG_TYPE_ADD;
      this.teacherDialogData.visible = true;
    },

    // handle 编辑
    handleEdit(row) {
      this.teacherDialogData.optionType = consts.DIALOG_TYPE_EDIT;
      this.teacherDialogData.data = row;
      this.teacherDialogData.visible = true;
    },

    // handle 修改密码
    handleChangePassword(row) {
      this.passwordDialogData.optionType = consts.PASSWORD_RESET_ONE;
      this.passwordDialogData.teachers = [row];
      this.passwordDialogData.visible = true;
    },

    // handle 修改密码成功
    handlePasswordSuccess() {
      this.passwordDialogData.visible = false;
    },

    // handle 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (val.length) {
        this.selectAll = false;
      }
    },

    // handle 批量重置密码
    handleBatchReset() {
      this.passwordDialogData.optionType = consts.PASSWORD_RESET_BATCH;
      this.passwordDialogData.teachers = this.selectedRows;
      this.passwordDialogData.visible = true;
    },

    // 批量删除老师
    handleBatchDelete() {
      deleteTeachers({
        userIdList: this.selectedRows.map((x) => x.userId),
      }).then((res) => {
        if (res.status === 0) {
          this.$message.success('删除成功');
          this.selectedRows = [];
          this.batchDeleteDialogData.visible = false;
          this.getTableData();
        }
      });
    },

    // 删除老师
    handleDelete(val) {
      const userIdList = val ? [val.userId] : this.selectedRows.map((x) => x.userId);
      const userNameList = val ? [val.name] : this.selectedRows.map((x) => x.name);

      this.$msgbox({
        message: `你确定删除${userNameList.join()}吗？`,
        type: 'warning',
        customClass: 'ql-message-box_notitle',
        showClose: false,
        showCancelButton: true,
      })
        .then(async () => {
          const res = await deleteTeachers({
            userIdList,
          });

          if (res.status === 0) {
            this.$message.success('删除成功');
            this.selectedRows = this.selectedRows.filter((item) => !userIdList.includes(item.userId));
            this.getTableData();
          }
        })
        .catch((handle) => {
          if (handle === 'cancel') {
            this.$message({
              type: 'info',
              message: '已取消操作',
            });
          }
        });
    },

    async getTableData() {
      const res = await getTeacherTable({
        pageSize: this.pagination.pageSize,
        pageIndex: this.pagination.page,
        commonFilter: this.filterOptions.commonFilter,
      });

      if (res.status === 0) {
        this.table.list = res.result.data;
        this.table.total = +res.result.recordCount;
        this.pagination.total = +res.result.recordCount;
      }
    },

    async getAllTableData() {
      const res = await getTeacherTable({
        pageSize: this.pagination.total,
        pageIndex: 1,
        commonFilter: this.filterOptions.commonFilter,
      });

      if (res.status === 0) {
        this.selectedRows = res.result.data;
      }
    },

    // 批量录入
    handleImport() {
      this.$batchEntry({
        type: 'teacher',
        downloadMethod: 'GET',
        callback: () => {
          this.getTableData();
        },
      });
    },

    // 批量导出
    handleExport() {
      this.$batchExport({
        type: 'teacher',
        downloadMethod: 'POST',
        data: {
          userIdList: this.selectedRows.map((item) => item.userId),
          ...this.filterOptions,
        },
      });
    },
  },

  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },

    notAllowOperate() {
      // return this.userInfo.thirdpartySchoolProjectCode !== '';
      return false;
    },

    columns() {
      return [
        {
          label: '账号',
          prop: 'loginName',
        },
        {
          label: '教师姓名',
          prop: 'name',
        },
        {
          label: '手机号',
          prop: 'mobileNo',
        },
        {
          label: '教职工号',
          prop: 'idNumber',
        },
        {
          label: '性别',
          prop: 'sex',
          formatter: (row) => ({ 1: '男', 2: '女' }[row.sex]),
        },
        {
          label: '操作',
          props: () => ({
            btnList: [
              {
                text: '编辑',
                disabled: () => this.notAllowOperate,
                handle: this.handleEdit,
              },
              {
                text: '删除',
                disabled: () => this.notAllowOperate,
                handle: this.handleDelete,
              },
              {
                text: '修改密码',
                disabled: () => this.notAllowOperate,
                handle: this.handleChangePassword,
              },
            ],
          }),
          component: OperationBtns,
        },
      ];
    },
  },

  watch: {
    filterOptions: {
      handler() {
        this.pagination.page = 1;
        this.getTableData();
      },
      deep: true,
    },
  },

  mounted() {
    this.getTableData();
  },
};
