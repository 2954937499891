<template>
  <div>
    <el-dialog
      custom-class="ql-dialog "
      class="password-dialog"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="589px"
      v-loading="isLoading"
      element-loading-background="rgba(0, 0, 0, 0.1)"
      @close="handleCancel"
    >
      <div class="tips">
        <svg class="fz-18 icon" aria-hidden="true">
          <use xlink:href="#icon-icon_jinggao"></use>
        </svg>
        <p>你确定修改以下教师密码为：{{ form.optionType === 0 ? '默认密码' : '自定义密码' }}吗？</p>
      </div>

      <el-form :model="form" :rules="rules" ref="passwordForm" label-width="auto">
        <el-form-item label="教师名单">
          <div class="nameBox">
            <span v-for="name in teacherNames" :key="name">{{ name }}</span>
          </div>
        </el-form-item>

        <el-form-item label="密码类型" prop="optionType" size="small">
          <el-radio-group v-model="form.optionType">
            <el-radio :label="TYPE_DEFAULT_PASSWORD">默认密码</el-radio>
            <el-radio :label="TYPE_NEW_PASSWORD">新密码</el-radio>
          </el-radio-group>

          <div v-if="form.optionType === TYPE_DEFAULT_PASSWORD" class="defaultTips">
            <p>
              当教师有教职工号时，默认为qljy+教职工号后4位，不足4位则以0补位，例：qljy0001；当教师无教职工号时，默认为qljy0000
            </p>
          </div>
        </el-form-item>

        <template v-if="form.optionType === TYPE_NEW_PASSWORD">
          <el-form-item label="新密码" prop="password" size="small">
            <el-input
              placeholder="请输入新密码"
              :type="showPassword ? 'text' : 'password'"
              v-model.trim="form.password"
            >
              <i
                slot="suffix"
                :class="
                  'mr-10px iconfont cursor-pointer text-info ' +
                  (showPassword ? 'icon-xianshi-zheng' : 'icon-yincang_bi')
                "
                @click="showPassword = !showPassword"
              />
            </el-input>
            <div class="tip">8-24位，至少包含数字、字母或特殊字符中两种方式</div>
          </el-form-item>
        </template>
      </el-form>

      <span slot="footer">
        <el-button size="small" @click="handleCancel">取消</el-button>
        <el-button type="primary" size="small" v-debounceClick="handleSave" :loading="loading">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { editTeacherPassword } from '@/api/basic';

import { Dialog, Form, FormItem, Button, Input, RadioGroup, Radio } from 'element-ui';
import { checkPassword } from '@/utils/validate';
import { PASSWORD_RESET_ONE } from '../constance';
import { rsaEncryption } from '@/utils/common';

const TYPE_NEW_PASSWORD = 1;
const TYPE_DEFAULT_PASSWORD = 0;

export default {
  components: {
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Input.name]: Input,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    optionType: Number,
    teachers: Array,
  },

  computed: {
    dialogTitle() {
      return this.optionType === PASSWORD_RESET_ONE ? '修改教师密码' : '重置教师密码';
    },

    teacherNames() {
      return this.teachers.map((x) => x.name);
    },
  },

  data() {
    const validatePassword = (rule, value, callback) => {
      const res = checkPassword(value);

      if (res !== true) {
        callback(new Error(res));
        return;
      }

      callback();
    };

    return {
      TYPE_NEW_PASSWORD,
      TYPE_DEFAULT_PASSWORD,
      PASSWORD_RESET_ONE,

      loading: false,
      isLoading: false,
      dialogVisible: false,
      form: {
        optionType: TYPE_DEFAULT_PASSWORD,
        password: '',
      },
      rules: {
        password: [{ validator: validatePassword, trigger: 'blur' }],
      },

      showPassword: false, // 密码可见性
    };
  },

  methods: {
    handleCancel() {
      this.$emit('close');
    },

    // 保存密码
    handleSave() {
      this.loading = true;

      this.$refs.passwordForm.validate(async (valid) => {
        if (valid) {
          let data = {
            userIdList: this.teachers.map((x) => x.userId),
            passwordType: this.form.optionType,
          };

          if (this.form.optionType === TYPE_NEW_PASSWORD) {
            data.password = rsaEncryption(this.form.password);
          }

          const res = await editTeacherPassword(data).catch(() => (this.loading = false));

          if (res.status === 0) {
            this.$message.success(this.optionType === PASSWORD_RESET_ONE ? `修改密码成功` : '重置密码成功');
            this.$emit('success');
          }
        } else {
          this.loading = false;
        }
      });
    },
  },

  watch: {
    visible: {
      handler: function (val) {
        Object.assign(this.$data, this.$options.data()); // reset
        this.loading = false;
        this.dialogVisible = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tips {
  display: flex;
  align-items: center;
  padding: 0 17px;
  gap: 13px;
  height: 48px;
  background-color: #fffbf4;
  position: absolute;
  width: 100%;
  left: 0;
  top: 66px;
  color: #303333;
  line-height: normal;
}

.nameBox {
  background: #f8f8f8;
  @apply pl-15px pr-15px py-8px flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 8px;
  max-height: 222px;
  overflow: auto;

  span {
    @apply text-F1;
    line-height: 22px;
  }
}

.defaultTips {
  background-color: #f8f8f8;
  padding: 8px 12px;
  font-size: 12px;
  color: #7c8083;
  line-height: normal;
  margin-top: 8px;
}

.password-dialog {
  .tip {
    @apply text-12;
    line-height: 1.5;
    margin-top: 5px;
    color: #b9b9b9;
  }

  // 外层轨道
  ::-webkit-scrollbar-track {
    background-color: #f8f8f8;
  }

  ::v-deep .el-dialog__body {
    padding-top: 64px;
  }
}
</style>
