<template>
  <Dialog
    custom-class="fit-width-content"
    :title="typeWord + '教师'"
    :visible.sync="dialogVisible"
    width="586px"
    @close="handleCancel"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="90px">
      <el-form-item label="教师姓名" prop="name" size="small">
        <el-input v-model.trim="form.name" placeholder="请输入教师姓名" />
      </el-form-item>

      <el-form-item label="手机号" prop="mobileNo" size="small">
        <el-input v-model.trim="form.mobileNo" placeholder="请输入手机号" />
      </el-form-item>

      <el-form-item label="教职工号" prop="idNumber" size="small">
        <el-input v-model.trim="form.idNumber" placeholder="请输入教职工号" />
      </el-form-item>

      <el-form-item label="性别" prop="sex" size="small">
        <el-radio-group v-model="form.sex">
          <el-radio :label="1" @click.native.prevent="handleRadio(1)">男</el-radio>
          <el-radio :label="2" @click.native.prevent="handleRadio(2)">女</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="optionType === DIALOG_TYPE_ADD" label="密码" prop="password" size="small">
        <el-input v-model.trim="form.password" placeholder="请输入密码"></el-input>
        <div class="tips">
          <p class="tip-content">8-24位，至少包含数字、字母或特殊字符中两种方式</p>
          <p class="tip-content">
            若不填写密码，当教师有职工号时，默认为qljy+职工号后4位，不足4位则以0补位，例：qljy0001；当教师无教职工号时，默认为qljy0000
          </p>
        </div>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button @click="handleCancel" size="small">取消</el-button>
      <el-button type="primary" v-debounceClick="handleSave" :loading="loading" size="small">确定</el-button>
    </span>
  </Dialog>
</template>

<script>
import { Form, FormItem, Button, Input, Radio, RadioGroup } from 'element-ui';
import { addTeacher, editTeacher } from '@/api/basic';
import { DIALOG_TYPE_ADD, DIALOG_TYPE_EDIT } from '../constance';
import { checkQuote, checkPassword, checkPhone } from '@/utils/validate';
import Dialog from '@/components/dialog/dialog.vue';
import { rsaEncryption } from '@/utils/common';

export default {
  name: 'TeacherDialog',

  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    // [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Input.name]: Input,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    Dialog,
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },

    optionType: {
      type: Number,
      required: true,
      default: DIALOG_TYPE_ADD,
    },

    data: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    // 自定义手机号验证
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback();
      }

      const checkMsg = checkPhone(value);
      if (checkMsg === true) {
        callback();
      } else {
        callback(new Error(checkMsg));
      }
    };

    const validateUserName = (rule, value, callback) => {
      if ((value || '').trim().length === 0) {
        callback(new Error('姓名不能为空'));
        return;
      }

      if (value.trim().length > 15) {
        callback(new Error('姓名过长'));
        return;
      }

      if (!checkQuote(value)) {
        callback(new Error('姓名不能包含特殊字符'));
        return;
      }

      callback();
    };

    const validatePassword = (rule, value, callback) => {
      if (value && checkPassword(value) !== true) {
        callback(new Error('8-24位，至少包含数字、字母或特殊字符中两种方式'));
        return;
      }

      callback();
    };

    return {
      loading: false,
      DIALOG_TYPE_ADD,
      DIALOG_TYPE_EDIT,
      dialogVisible: false,
      form: {
        name: '',
        mobileNo: '',
        idNumber: '',
        sex: '',
        password: '',
      },
      rules: {
        name: [
          { required: true, message: '教师姓名不能为空', trigger: ['blur', 'change'] },
          { validator: validateUserName, trigger: ['blur', 'change'] },
        ],
        mobileNo: [{ validator: validatePhone, trigger: ['blur', 'change'] }],
        password: [{ validator: validatePassword, trigger: ['blur', 'change'] }],
      },
    };
  },

  methods: {
    handleRadio(val) {
      if (this.form.sex === val) {
        this.form.sex = '';
      } else {
        this.form.sex = val;
      }
    },

    handleSave() {
      this.loading = true;

      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = {
            userId: this.form.userId,
            name: this.form.name,
            mobileNo: this.form.mobileNo,
            sex: this.form.sex,
            idNumber: this.form.idNumber,
            password: this.form.password,
          };

          if (this.form.password) {
            const res = checkPassword(this.form.password);

            if (res !== true) {
              this.$message.error(res);
              return;
            }

            params.password = rsaEncryption(params.password);
          }

          if (this.optionType === DIALOG_TYPE_ADD) {
            addTeacher(params)
              .then((res) => {
                if (res.status === 0) {
                  this.$message.success(`${this.typeWord}教师成功`);
                  this.$emit('success');
                }
              })
              .catch(() => (this.loading = false));
          } else {
            editTeacher(params)
              .then((res) => {
                if (res.status === 0) {
                  this.$message.success(`${this.typeWord}教师成功`);
                  this.$emit('success');
                }
              })
              .catch(() => (this.loading = false));
          }
        } else {
          this.loading = false;
        }
      });
    },

    handleCancel() {
      this.$emit('close');
    },
  },

  computed: {
    typeWord() {
      switch (this.optionType) {
        case DIALOG_TYPE_ADD:
          return '新增';
        case DIALOG_TYPE_EDIT:
          return '编辑';
        default:
          return '';
      }
    },
  },

  watch: {
    visible: {
      handler: function (val) {
        if (val) {
          // reset
          Object.assign(this.$data, this.$options.data());
          this.loading = false;

          this.$nextTick(() => {
            this.$refs.form.clearValidate();
          });

          // 编辑
          if (this.optionType === DIALOG_TYPE_EDIT) {
            this.form = Object.assign(this.form, this.data);
          }
        }

        this.dialogVisible = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tips {
  @apply T6 text-F2;
  margin-top: 8px;
  padding: 8px 12px;
  background-color: #f8f8f8;
  border-radius: 4px;
  width: 400px;
}
</style>
